/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();

  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  padding: 0 3em;
}

.o-wrapper--tablet {
  max-width: 650px;
}

.o-wrapper--narrow {
  max-width: 1024px;
}

.o-wrapper--wide {
  max-width: 1440px;
}
