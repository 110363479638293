.c-career {
  padding: 1.5em 0.5em 1em;
  transition: $global-transition;

  &:hover {
    background: $color-super-light-grey;
  }

  &__title {
    font-weight: bold;
  }

  &__icon {
    content: url("data:image/svg+xml,%3Csvg width='24' height='22' viewBox='0 0 24 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14 3H1V21H21V10H22V22H0V2H14V3ZM24 8H23V1.707L11.354 13.354L10.646 12.646L22.293 1H16V0H24V8Z' fill='%23585858'/%3E%3C/svg%3E%0A");
    vertical-align: top;
    margin-left: 1em;

    @include bp-down(medium) {
      float: right;
    }
  }

  &__tag {
    margin-right: 0.5em;
  }
}
