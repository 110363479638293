.u-ratio {
  &--1-1 {
    aspect-ratio: 1/1;

    img,
    video {
      width: 100%;
      height: 100% !important;
      object-fit: cover;
    }
  }
}
