input {
  &[type='submit'],
  &[type='file']::file-selector-button {
    padding: 0.75rem 1rem;
    transition: $global-transition;
    border-radius: $global-radius * 2;
    color: $color-white;
    background-color: $color-dark-grey;
    border: none;
    cursor: pointer;
  }
}
