.c-credits-clip__video {
  position: relative;
}

.c-credits-clip__poster {
  width: 100%;
}

.c-credits-clip__play {
  background-image: url("data:image/svg+xml,%3Csvg width='24' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.58301 0.75L14.4163 9L1.58301 17.25V0.75Z' stroke='white' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  padding: 2em;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: $global-transition;
  animation: pulse 0.75s infinite alternate;

  &:hover {
    animation: none;
    background-color: rgba(0, 0, 0, 1);
    padding: 2.75em;
  }
}

@keyframes pulse {
  from {
    padding: 2em;
  }

  to {
    padding: 2.75em;
  }
}

.c-credits-clip__content {
  margin: 2em 0;
}

.c-credits-clip__credits {
  margin: 0;
  background-color: $color-very-light-grey;
  padding: 2em;

  @include bp(medium) {
    margin-top: -6em;
    margin-left: 1em;
  }
}

.c-credits-clip__credit {
  list-style-type: none;
  margin-bottom: 1.5em;

  &:last-of-type {
    margin-bottom: 0;
  }
}
