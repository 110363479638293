%width-cover {
  width: 100%;
  object-fit: cover;
}

.u-image {
  &--1-1 {
    @extend %width-cover;

    aspect-ratio: 1/1;
  }

  &--16-9 {
    @extend %width-cover;

    aspect-ratio: 16/9;
  }
}
