/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

%legibility {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.c-post {
  @include clearfix();

  margin-top: 20vh;
  margin-bottom: 10vh;

  &__title {
    margin: 0;
  }
}

.c-post__header,
.c-post__content > p,
.c-post__content > h1,
.c-post__content > h2,
.c-post__content > h3,
.c-post__content > h4 {
  @extend %legibility;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}
