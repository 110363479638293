.c-locale-nav {
  display: flex;
  align-items: center;
}

.c-locale-nav__list {
  display: flex;
  list-style-type: none;
  gap: 1em;
  margin: 0;
}

.c-locale-selected {
  border-bottom: 1px solid;
  display: inline-block;
}
