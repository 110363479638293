/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  font-size: 0.875rem;
  padding: 1rem;
  color: $color-light-grey;
  background-color: $color-dark-grey;
  align-items: flex-end;

  @include bp(medium) {
    padding: 2rem;
  }

  @include bp(xlarge) {
    padding: 3rem;
  }

  > *:last-child {
    @include bp(medium) {
      text-align: right;
    }
  }
}
