/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100%;
  padding: 1rem;
  transition: padding 300ms linear;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include bp(medium) {
    padding: 2rem;
  }

  @include bp(xlarge) {
    padding: 3rem;
  }

  > .o-layout__item {
    flex: none;
  }

  a {
    border-bottom: none;
  }

  img {
    height: 2.5em;
    transition: height 300ms linear, filter 350ms 300ms linear;
  }
}

.c-header__menus {
  display: flex;
  gap: 2em;
}

.c-header--invert,
.c-header--invert-logo > a {
  filter: invert(1);
}
