.c-related {
  min-height: 50vh;
  background-color: $color-black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.c-related__inner {
  padding: 0 3em;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
}

.c-related__item {
  width: 80vw;
  max-width: 400px;
  display: inline-block;
  color: $color-white;
  white-space: normal;
  vertical-align: top;
  margin-right: 7em;
}

.c-related__intro,
.c-related__link {
  position: relative;
  z-index: 1;
}

.c-related__intro {
  width: 80vw;
  max-width: 650px;
}

.c-related__link {
  display: inline-block;
  transition: $global-transition;
  opacity: 0.25;

  &:hover {
    opacity: 1;
  }
}

.c-related__image {
  transition: $global-transition;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  object-fit: cover;
  opacity: 0;
  height: 100%;
  width: 100%;
}

.c-related__link:hover + .c-related__image {
  opacity: 0.33;
}
