%content {
  @include bp(medium) {
    padding: 0 2rem 2rem;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  @include bp(xlarge) {
    padding: 0 3rem 3rem;
  }
}

%video {
  object-fit: cover;
  z-index: 0;
}

.c-clip {
  margin-left: auto;
  margin-right: auto;

  &__video {
    width: 100%;
    position: relative;
    display: block;
  }
}

.c-clip--overlay {
  position: relative;

  .c-clip__video {
    @extend %video;
  }

  .c-clip__content {
    @extend %content;
  }
}

.c-clip--fullscreen {
  position: relative;
  max-width: none;

  @include bp-down(medium) {
    display: flex;
    flex-direction: column-reverse;
  }

  .c-clip__video {
    @extend %video;

    height: 100vh;
  }

  .c-clip__content {
    @extend %content;

    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    @include bp-down(medium) {
      padding: 1rem;
    }
  }
}

.c-clip--scrolling {
  position: relative;
  max-width: none;
  height: 200vh;

  .c-clip__video {
    @extend %video;

    height: 100vh;
    position: sticky;
    top: 0;
  }

  .c-clip__content {
    @extend %content;

    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
