/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: $color-link;
  text-decoration: none;
  border-bottom: 0.05em solid;

  &:hover {
    color: $color-hover;
  }

  &[href*='google.com/maps'],
  &[href*='goo.gl/maps'] {
    content: url("data:image/svg+xml,%3Csvg height='24' viewBox='0 0 19 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.08969 0C4.06961 0 0 4.06961 0 9.08969C0 11.3308 0.814795 13.379 2.15943 14.9639L9.08969 23.1257L16.0197 14.9636C17.3643 13.379 18.1791 11.3306 18.1791 9.08943C18.1794 4.06961 14.1098 0 9.08969 0ZM9.08969 12.5149C7.02507 12.5149 5.35154 10.8413 5.35154 8.77672C5.35154 6.71211 7.02507 5.03858 9.08969 5.03858C11.1543 5.03858 12.8278 6.71211 12.8278 8.77672C12.8278 10.8413 11.1543 12.5149 9.08969 12.5149Z' fill='%23A1A1A1'/%3E%3C/svg%3E");
    vertical-align: bottom;
    margin: 0 0.35em;
    border-bottom: none;
  }

  &[href*='twitter.com'] {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ababab' width='21' height='21' viewBox='0 0 24 24'%3E%3Cpath d='M24 4.37a9.6 9.6 0 0 1-2.83.8 5.04 5.04 0 0 0 2.17-2.8c-.95.58-2 1-3.13 1.22A4.86 4.86 0 0 0 16.61 2a4.99 4.99 0 0 0-4.79 6.2A13.87 13.87 0 0 1 1.67 2.92 5.12 5.12 0 0 0 3.2 9.67a4.82 4.82 0 0 1-2.23-.64v.07c0 2.44 1.7 4.48 3.95 4.95a4.84 4.84 0 0 1-2.22.08c.63 2.01 2.45 3.47 4.6 3.51A9.72 9.72 0 0 1 0 19.74 13.68 13.68 0 0 0 7.55 22c9.06 0 14-7.7 14-14.37v-.65c.96-.71 1.79-1.6 2.45-2.61z'%3E%3C/path%3E%3C/svg%3E");
    margin: 0 0.35em;
    border-bottom: none;
  }

  &[href*='instagram.com'] {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ababab' width='21' height='21' viewBox='0 0 24 24'%3E%3Cpath d='M16.98 0a6.9 6.9 0 0 1 5.08 1.98A6.94 6.94 0 0 1 24 7.02v9.96c0 2.08-.68 3.87-1.98 5.13A7.14 7.14 0 0 1 16.94 24H7.06a7.06 7.06 0 0 1-5.03-1.89A6.96 6.96 0 0 1 0 16.94V7.02C0 2.8 2.8 0 7.02 0h9.96zm.05 2.23H7.06c-1.45 0-2.7.43-3.53 1.25a4.82 4.82 0 0 0-1.3 3.54v9.92c0 1.5.43 2.7 1.3 3.58a5 5 0 0 0 3.53 1.25h9.88a5 5 0 0 0 3.53-1.25 4.73 4.73 0 0 0 1.4-3.54V7.02a5 5 0 0 0-1.3-3.49 4.82 4.82 0 0 0-3.54-1.3zM12 5.76c3.39 0 6.2 2.8 6.2 6.2a6.2 6.2 0 0 1-12.4 0 6.2 6.2 0 0 1 6.2-6.2zm0 2.22a3.99 3.99 0 0 0-3.97 3.97A3.99 3.99 0 0 0 12 15.92a3.99 3.99 0 0 0 3.97-3.97A3.99 3.99 0 0 0 12 7.98zm6.44-3.77a1.4 1.4 0 1 1 0 2.8 1.4 1.4 0 0 1 0-2.8z'%3E%3C/path%3E%3C/svg%3E");
    margin: 0 0.35em;
    border-bottom: none;
  }

  &[href*='linkedin.com'] {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ababab' width='21' height='21' viewBox='0 0 24 24'%3E%3Cpath d='M22.23 0H1.77C.8 0 0 .77 0 1.72v20.56C0 23.23.8 24 1.77 24h20.46c.98 0 1.77-.77 1.77-1.72V1.72C24 .77 23.2 0 22.23 0zM7.27 20.1H3.65V9.24h3.62V20.1zM5.47 7.76h-.03c-1.22 0-2-.83-2-1.87 0-1.06.8-1.87 2.05-1.87 1.24 0 2 .8 2.02 1.87 0 1.04-.78 1.87-2.05 1.87zM20.34 20.1h-3.63v-5.8c0-1.45-.52-2.45-1.83-2.45-1 0-1.6.67-1.87 1.32-.1.23-.11.55-.11.88v6.05H9.28s.05-9.82 0-10.84h3.63v1.54a3.6 3.6 0 0 1 3.26-1.8c2.39 0 4.18 1.56 4.18 4.89v6.21z'%3E%3C/path%3E%3C/svg%3E");
    margin: 0 0.35em;
    border-bottom: none;
  }
}
