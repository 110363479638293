.c-hero {
  &__content {
    margin: 0 1em;
    padding: 2em;
  }
}

.c-hero--overlay {
  &__media {
    position: sticky;
    top: 0;
  }

  .c-hero__content {
    background-color: rgba(255, 255, 255, 0.75);

    @include bp-down(medium) {
      margin-top: -6em;
    }

    @include bp(large) {
      transform: translateX(-10em);
    }
  }
}
