.o-content {
  > *:not(.c-clip--fullscreen):not(.c-clip--scrolling):not(.c-hero):not(
      .c-marquee) {
    @include clearfix();

    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding: 0 1rem;

    @include bp(medium) {
      padding: 0 2rem;
    }

    @include bp(xlarge) {
      padding: 0 3rem;
    }
  }

  &--mtop {
    margin-top: 10em;
  }
}
