.c-parallax {
  height: 100vh;
  margin-bottom: 15vh;
  display: grid;
  place-items: center;

  &__img {
    position: relative;
  }

  &__body {
    background: $color-super-light-grey;
    position: relative;
    padding: 3em;

    @include bp(medium) {
      right: 10%;
    }
  }
}

.c-parallax--row-reverse {
  @include bp(medium) {
    .c-parallax__body {
      right: 0;
    }

    .c-parallax__img {
      right: 10%;
    }
  }
}
