.c-marquee__inner {
  @include bp(medium) {
    max-height: 600px;
    height: 50vh;
  }
}

.c-marquee__content {
  padding: 3em 0;
}

.c-marquee__scrolling {
  overflow: hidden;
  position: relative;
  height: 20em;

  @include bp(medium) {
    height: 100%;
  }

  &::before {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    content: '';
    position: absolute;
    background: linear-gradient(180deg, white, rgba(255, 255, 255, 0));
    height: 6em;

    @include bp(medium) {
      height: 30%;
    }
  }

  &::after {
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    content: '';
    position: absolute;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0), white);
    height: 6em;

    @include bp(medium) {
      height: 30%;
    }
  }
}

.c-marquee__list {
  margin: 0;
  animation: scrollUp 8s 0.16s infinite forwards linear;
  font-weight: bold;

  @include bp(medium) {
    padding: 0 3em;
  }
}

.c-marquee__item {
  list-style-type: none;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes scrollUp {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}
