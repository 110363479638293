/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */

blockquote {
  border-left: 0.25em solid;
  padding-left: 1em;

  p {
    font-size: 1.5em;
  }

  cite {
    font-size: 0.8125em;
  }
}
