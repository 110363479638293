.u-margin {
  margin-bottom: 1em;

  &-small {
    margin-bottom: 0.5em;
  }

  &-none {
    margin: 0 !important;
  }

  &-top {
    margin-top: 1em !important;
  }

  &-bottom {
    margin-bottom: 1em !important;
  }

  &-left {
    margin-left: 1em !important;
  }

  &-right {
    margin-right: 1em !important;
  }
}
