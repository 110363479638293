.wp-block-separator {
  border-bottom: none !important;
}

.wp-block-group.has-background {
  padding: 4em;
  max-width: 650px;
}

.wp-block-cover.has-parallax .wp-block-cover__inner-container {
  max-width: 1440px;
}

*[class*='wp-duotone'] a:hover img {
  filter: none !important;
}
