@charset "UTF-8";
/*
  Project: Milk VFX
  Author: Via Studios
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
@import-normalize;
/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
hr,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0; /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
ol,
ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd,
ol,
ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  border-left: 0.25em solid;
  padding-left: 1em;
}
blockquote p {
  font-size: 1.5em;
}
blockquote cite {
  font-size: 0.8125em;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #fff;
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 0.875em; /* [1] */ /* 14px */
  line-height: 1.333; /* [1] */
  min-height: 100%; /* [3] */
  overflow-y: scroll; /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
}
@media (min-width: 768px) {
  html {
    font-size: 1em; /* 16px */
  }
}
@media (min-width: 1024px) {
  html {
    font-size: 1.125em; /* 18px */
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic; /* [2] */
  max-width: 100%; /* [1] */
  vertical-align: middle; /* [3] */
  height: auto;
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
input[type=submit], input[type=file]::file-selector-button {
  padding: 0.75rem 1rem;
  transition: all 300ms ease-out;
  border-radius: 6px;
  color: #fff;
  background-color: #242424;
  border: none;
  cursor: pointer;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: inherit;
  text-decoration: none;
  border-bottom: 0.05em solid;
}
a:hover {
  color: inherit;
}
a[href*="google.com/maps"], a[href*="goo.gl/maps"] {
  content: url("data:image/svg+xml,%3Csvg height='24' viewBox='0 0 19 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.08969 0C4.06961 0 0 4.06961 0 9.08969C0 11.3308 0.814795 13.379 2.15943 14.9639L9.08969 23.1257L16.0197 14.9636C17.3643 13.379 18.1791 11.3306 18.1791 9.08943C18.1794 4.06961 14.1098 0 9.08969 0ZM9.08969 12.5149C7.02507 12.5149 5.35154 10.8413 5.35154 8.77672C5.35154 6.71211 7.02507 5.03858 9.08969 5.03858C11.1543 5.03858 12.8278 6.71211 12.8278 8.77672C12.8278 10.8413 11.1543 12.5149 9.08969 12.5149Z' fill='%23A1A1A1'/%3E%3C/svg%3E");
  vertical-align: bottom;
  margin: 0 0.35em;
  border-bottom: none;
}
a[href*="twitter.com"] {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ababab' width='21' height='21' viewBox='0 0 24 24'%3E%3Cpath d='M24 4.37a9.6 9.6 0 0 1-2.83.8 5.04 5.04 0 0 0 2.17-2.8c-.95.58-2 1-3.13 1.22A4.86 4.86 0 0 0 16.61 2a4.99 4.99 0 0 0-4.79 6.2A13.87 13.87 0 0 1 1.67 2.92 5.12 5.12 0 0 0 3.2 9.67a4.82 4.82 0 0 1-2.23-.64v.07c0 2.44 1.7 4.48 3.95 4.95a4.84 4.84 0 0 1-2.22.08c.63 2.01 2.45 3.47 4.6 3.51A9.72 9.72 0 0 1 0 19.74 13.68 13.68 0 0 0 7.55 22c9.06 0 14-7.7 14-14.37v-.65c.96-.71 1.79-1.6 2.45-2.61z'%3E%3C/path%3E%3C/svg%3E");
  margin: 0 0.35em;
  border-bottom: none;
}
a[href*="instagram.com"] {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ababab' width='21' height='21' viewBox='0 0 24 24'%3E%3Cpath d='M16.98 0a6.9 6.9 0 0 1 5.08 1.98A6.94 6.94 0 0 1 24 7.02v9.96c0 2.08-.68 3.87-1.98 5.13A7.14 7.14 0 0 1 16.94 24H7.06a7.06 7.06 0 0 1-5.03-1.89A6.96 6.96 0 0 1 0 16.94V7.02C0 2.8 2.8 0 7.02 0h9.96zm.05 2.23H7.06c-1.45 0-2.7.43-3.53 1.25a4.82 4.82 0 0 0-1.3 3.54v9.92c0 1.5.43 2.7 1.3 3.58a5 5 0 0 0 3.53 1.25h9.88a5 5 0 0 0 3.53-1.25 4.73 4.73 0 0 0 1.4-3.54V7.02a5 5 0 0 0-1.3-3.49 4.82 4.82 0 0 0-3.54-1.3zM12 5.76c3.39 0 6.2 2.8 6.2 6.2a6.2 6.2 0 0 1-12.4 0 6.2 6.2 0 0 1 6.2-6.2zm0 2.22a3.99 3.99 0 0 0-3.97 3.97A3.99 3.99 0 0 0 12 15.92a3.99 3.99 0 0 0 3.97-3.97A3.99 3.99 0 0 0 12 7.98zm6.44-3.77a1.4 1.4 0 1 1 0 2.8 1.4 1.4 0 0 1 0-2.8z'%3E%3C/path%3E%3C/svg%3E");
  margin: 0 0.35em;
  border-bottom: none;
}
a[href*="linkedin.com"] {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ababab' width='21' height='21' viewBox='0 0 24 24'%3E%3Cpath d='M22.23 0H1.77C.8 0 0 .77 0 1.72v20.56C0 23.23.8 24 1.77 24h20.46c.98 0 1.77-.77 1.77-1.72V1.72C24 .77 23.2 0 22.23 0zM7.27 20.1H3.65V9.24h3.62V20.1zM5.47 7.76h-.03c-1.22 0-2-.83-2-1.87 0-1.06.8-1.87 2.05-1.87 1.24 0 2 .8 2.02 1.87 0 1.04-.78 1.87-2.05 1.87zM20.34 20.1h-3.63v-5.8c0-1.45-.52-2.45-1.83-2.45-1 0-1.6.67-1.87 1.32-.1.23-.11.55-.11.88v6.05H9.28s.05-9.82 0-10.84h3.63v1.54a3.6 3.6 0 0 1 3.26-1.8c2.39 0 4.18 1.56 4.18 4.89v6.21z'%3E%3C/path%3E%3C/svg%3E");
  margin: 0 0.35em;
  border-bottom: none;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}
li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%; /* [1] */
}

body .cc-revoke,
body .cc-window {
  font-family: Inter, sans-serif !important;
}
body .cc-link {
  text-decoration: none !important;
  padding: 0 !important;
}
body .cc-btn {
  font-weight: normal !important;
}

.wp-block-separator {
  border-bottom: none !important;
}

.wp-block-group.has-background {
  padding: 4em;
  max-width: 650px;
}

.wp-block-cover.has-parallax .wp-block-cover__inner-container {
  max-width: 1440px;
}

*[class*=wp-duotone] a:hover img {
  filter: none !important;
}

.o-content > *:not(.c-clip--fullscreen):not(.c-clip--scrolling):not(.c-hero):not(.c-marquee) {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding: 0 1rem;
}
.o-content > *:not(.c-clip--fullscreen):not(.c-clip--scrolling):not(.c-hero):not(.c-marquee)::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}
@media (min-width: 768px) {
  .o-content > *:not(.c-clip--fullscreen):not(.c-clip--scrolling):not(.c-hero):not(.c-marquee) {
    padding: 0 2rem;
  }
}
@media (min-width: 1280px) {
  .o-content > *:not(.c-clip--fullscreen):not(.c-clip--scrolling):not(.c-hero):not(.c-marquee) {
    padding: 0 3rem;
  }
}
.o-content--mtop {
  margin-top: 10em;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}
@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout--row-vcenter {
  align-items: center;
}

.o-layout--row-reverse {
  flex-direction: row-reverse;
}

.o-layout__item {
  flex: 1;
}

.o-layout--wrap {
  flex-wrap: wrap;
}

.o-layout--guttered {
  justify-content: space-between;
}
.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

@media (min-width: 768px) {
  .o-layout--2-1 > .o-layout__item:first-of-type {
    width: 64%;
  }
}
@media (min-width: 768px) {
  .o-layout--2-1 > .o-layout__item:last-of-type {
    width: 32%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}
.o-media::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}
.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}
.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  padding: 0 3em;
}
.o-wrapper::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.o-wrapper--tablet {
  max-width: 650px;
}

.o-wrapper--narrow {
  max-width: 1024px;
}

.o-wrapper--wide {
  max-width: 1440px;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn {
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  padding: 0.5rem 1rem;
  transition: all 300ms ease-out;
  border-radius: 3px;
}

/* Style variants
   ========================================================================== */
.c-btn--primary {
  background-color: inherit;
}
.c-btn--primary, .c-btn--primary:hover, .c-btn--primary:active, .c-btn--primary:focus {
  text-decoration: none; /* [4] */
  color: #fff;
}
.c-btn--primary:hover, .c-btn--primary:focus {
  background-color: inherit;
}

/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

.c-career {
  padding: 1.5em 0.5em 1em;
  transition: all 300ms ease-out;
}
.c-career:hover {
  background: #ebebeb;
}
.c-career__title {
  font-weight: bold;
}
.c-career__icon {
  content: url("data:image/svg+xml,%3Csvg width='24' height='22' viewBox='0 0 24 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14 3H1V21H21V10H22V22H0V2H14V3ZM24 8H23V1.707L11.354 13.354L10.646 12.646L22.293 1H16V0H24V8Z' fill='%23585858'/%3E%3C/svg%3E%0A");
  vertical-align: top;
  margin-left: 1em;
}
@media (max-width: 767.98px) {
  .c-career__icon {
    float: right;
  }
}
.c-career__tag {
  margin-right: 0.5em;
}

@media (min-width: 768px) {
  .c-clip--scrolling .c-clip__content, .c-clip--fullscreen .c-clip__content, .c-clip--overlay .c-clip__content {
    padding: 0 2rem 2rem;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}
@media (min-width: 1280px) {
  .c-clip--scrolling .c-clip__content, .c-clip--fullscreen .c-clip__content, .c-clip--overlay .c-clip__content {
    padding: 0 3rem 3rem;
  }
}

.c-clip--scrolling .c-clip__video, .c-clip--fullscreen .c-clip__video, .c-clip--overlay .c-clip__video {
  object-fit: cover;
  z-index: 0;
}

.c-clip {
  margin-left: auto;
  margin-right: auto;
}
.c-clip__video {
  width: 100%;
  position: relative;
  display: block;
}

.c-clip--overlay {
  position: relative;
}
.c-clip--fullscreen {
  position: relative;
  max-width: none;
}
@media (max-width: 767.98px) {
  .c-clip--fullscreen {
    display: flex;
    flex-direction: column-reverse;
  }
}
.c-clip--fullscreen .c-clip__video {
  height: 100vh;
}
.c-clip--fullscreen .c-clip__content {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .c-clip--fullscreen .c-clip__content {
    padding: 1rem;
  }
}

.c-clip--scrolling {
  position: relative;
  max-width: none;
  height: 200vh;
}
.c-clip--scrolling .c-clip__video {
  height: 100vh;
  position: sticky;
  top: 0;
}
.c-clip--scrolling .c-clip__content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}
.c-comment-form label {
  display: block;
}
.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

.c-credits-clip__video {
  position: relative;
}

.c-credits-clip__poster {
  width: 100%;
}

.c-credits-clip__play {
  background-image: url("data:image/svg+xml,%3Csvg width='24' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.58301 0.75L14.4163 9L1.58301 17.25V0.75Z' stroke='white' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  padding: 2em;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 300ms ease-out;
  animation: pulse 0.75s infinite alternate;
}
.c-credits-clip__play:hover {
  animation: none;
  background-color: rgb(0, 0, 0);
  padding: 2.75em;
}

@keyframes pulse {
  from {
    padding: 2em;
  }
  to {
    padding: 2.75em;
  }
}
.c-credits-clip__content {
  margin: 2em 0;
}

.c-credits-clip__credits {
  margin: 0;
  background-color: #ccc;
  padding: 2em;
}
@media (min-width: 768px) {
  .c-credits-clip__credits {
    margin-top: -6em;
    margin-left: 1em;
  }
}

.c-credits-clip__credit {
  list-style-type: none;
  margin-bottom: 1.5em;
}
.c-credits-clip__credit:last-of-type {
  margin-bottom: 0;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  font-size: 0.875rem;
  padding: 1rem;
  color: #ababab;
  background-color: #242424;
  align-items: flex-end;
}
@media (min-width: 768px) {
  .c-footer {
    padding: 2rem;
  }
}
@media (min-width: 1280px) {
  .c-footer {
    padding: 3rem;
  }
}
@media (min-width: 768px) {
  .c-footer > *:last-child {
    text-align: right;
  }
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100%;
  padding: 1rem;
  transition: padding 300ms linear;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .c-header {
    padding: 2rem;
  }
}
@media (min-width: 1280px) {
  .c-header {
    padding: 3rem;
  }
}
.c-header > .o-layout__item {
  flex: none;
}
.c-header a {
  border-bottom: none;
}
.c-header img {
  height: 2.5em;
  transition: height 300ms linear, filter 350ms 300ms linear;
}

.c-header__menus {
  display: flex;
  gap: 2em;
}

.c-header--invert,
.c-header--invert-logo > a {
  filter: invert(1);
}

.c-hero__content {
  margin: 0 1em;
  padding: 2em;
}

.c-hero--overlay__media {
  position: sticky;
  top: 0;
}
.c-hero--overlay .c-hero__content {
  background-color: rgba(255, 255, 255, 0.75);
}
@media (max-width: 767.98px) {
  .c-hero--overlay .c-hero__content {
    margin-top: -6em;
  }
}
@media (min-width: 1024px) {
  .c-hero--overlay .c-hero__content {
    transform: translateX(-10em);
  }
}

.c-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  animation-name: wrapper-in;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.c-lightbox__video {
  opacity: 0;
  max-width: 1280px;
  max-height: 70vh;
  margin: 0 auto;
  animation-name: video-in;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-delay: 600ms;
  width: calc(100vw - 6em);
  aspect-ratio: 16/9;
}
.c-lightbox__close {
  position: absolute;
  top: 3em;
  right: 3em;
  color: white !important;
  cursor: pointer;
}

@keyframes wrapper-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes video-in {
  from {
    opacity: 0;
    transform: translateY(2em);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.c-locale-nav {
  display: flex;
  align-items: center;
}

.c-locale-nav__list {
  display: flex;
  list-style-type: none;
  gap: 1em;
  margin: 0;
}

.c-locale-selected {
  border-bottom: 1px solid;
  display: inline-block;
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav {
  display: flex;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  bottom: 0;
  right: 0;
  font-size: 2em;
  font-weight: bold;
  z-index: -1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.3s ease-out;
}

.c-main-nav--active {
  opacity: 1;
  transform: translateY(0);
}

.c-main-nav__item {
  list-style: none;
  margin: 0 0 0.5em;
  padding: 0;
  width: calc(100vw - 3em);
}
@media (min-width: 768px) {
  .c-main-nav__item {
    width: 40vw;
  }
}
.c-main-nav__item:last-of-type {
  margin: 0;
}

.c-main-nav__link {
  display: block;
  border-bottom: none;
  transition: all 300ms ease-out;
}
@media (min-width: 768px) {
  .c-main-nav__link {
    display: flex;
    align-items: center;
  }
}

.current-menu-item .c-main-nav__link {
  border-bottom: 0.05em solid #ccc;
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}
.c-main-nav__item:hover .c-main-nav__dropdown {
  display: flex;
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  background: #fff;
  border-bottom: 1px solid #ccc;
  display: block;
  padding: 0.5em;
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: #000;
}

.c-main-nav-trigger {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
}
.c-main-nav-trigger::after {
  display: block;
  content: url("../assets/images/menu.svg");
}

.c-main-nav-trigger--active::after {
  display: block;
  content: url("../assets/images/close.svg");
}

.c-main-nav--white .c-main-nav__link {
  color: #fff;
}

@media (min-width: 768px) {
  .c-marquee__inner {
    max-height: 600px;
    height: 50vh;
  }
}

.c-marquee__content {
  padding: 3em 0;
}

.c-marquee__scrolling {
  overflow: hidden;
  position: relative;
  height: 20em;
}
@media (min-width: 768px) {
  .c-marquee__scrolling {
    height: 100%;
  }
}
.c-marquee__scrolling::before {
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  content: "";
  position: absolute;
  background: linear-gradient(180deg, white, rgba(255, 255, 255, 0));
  height: 6em;
}
@media (min-width: 768px) {
  .c-marquee__scrolling::before {
    height: 30%;
  }
}
.c-marquee__scrolling::after {
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  content: "";
  position: absolute;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0), white);
  height: 6em;
}
@media (min-width: 768px) {
  .c-marquee__scrolling::after {
    height: 30%;
  }
}

.c-marquee__list {
  margin: 0;
  animation: scrollUp 8s 0.16s infinite forwards linear;
  font-weight: bold;
}
@media (min-width: 768px) {
  .c-marquee__list {
    padding: 0 3em;
  }
}

.c-marquee__item {
  list-style-type: none;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes scrollUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
.c-parallax {
  height: 100vh;
  margin-bottom: 15vh;
  display: grid;
  place-items: center;
}
.c-parallax__img {
  position: relative;
}
.c-parallax__body {
  background: #ebebeb;
  position: relative;
  padding: 3em;
}
@media (min-width: 768px) {
  .c-parallax__body {
    right: 10%;
  }
}

@media (min-width: 768px) {
  .c-parallax--row-reverse .c-parallax__body {
    right: 0;
  }
  .c-parallax--row-reverse .c-parallax__img {
    right: 10%;
  }
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post__header,
.c-post__content > p,
.c-post__content > h1,
.c-post__content > h2,
.c-post__content > h3,
.c-post__content > h4 {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.c-post {
  margin-top: 20vh;
  margin-bottom: 10vh;
}
.c-post::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}
.c-post__title {
  margin: 0;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.c-related {
  min-height: 50vh;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.c-related__inner {
  padding: 0 3em;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.c-related__inner::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.c-related__item {
  width: 80vw;
  max-width: 400px;
  display: inline-block;
  color: #fff;
  white-space: normal;
  vertical-align: top;
  margin-right: 7em;
}

.c-related__intro,
.c-related__link {
  position: relative;
  z-index: 1;
}

.c-related__intro {
  width: 80vw;
  max-width: 650px;
}

.c-related__link {
  display: inline-block;
  transition: all 300ms ease-out;
  opacity: 0.25;
}
.c-related__link:hover {
  opacity: 1;
}

.c-related__image {
  transition: all 300ms ease-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  object-fit: cover;
  opacity: 0;
  height: 100%;
  width: 100%;
}

.c-related__link:hover + .c-related__image {
  opacity: 0.33;
}

.c-staggered-scroll {
  margin-top: 20vh;
  margin-bottom: 10vh;
}
@media (min-width: 768px) {
  .c-staggered-scroll__row {
    display: flex;
    justify-content: space-between;
  }
  .c-staggered-scroll__row:nth-child(even) {
    flex-direction: row-reverse;
  }
  .c-staggered-scroll__big {
    position: sticky;
    overflow: hidden;
    height: calc(100vh - 3.5em);
    width: 66%;
    top: 3.5em;
  }
  .c-staggered-scroll__big img {
    height: 82vh;
    width: 100%;
    object-fit: cover;
  }
  .c-staggered-scroll__small {
    width: 32%;
  }
}

.c-tag {
  text-decoration: none;
  border: 1px solid #ababab;
  padding: 0.1em 0.3em;
  font-size: 0.7em;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.u-p {
  font-size: 1rem;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}
@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

.u-image--16-9, .u-image--1-1 {
  width: 100%;
  object-fit: cover;
}

.u-image--1-1 {
  aspect-ratio: 1/1;
}
.u-image--16-9 {
  aspect-ratio: 16/9;
}

.u-link--bare {
  text-decoration: none;
  border-bottom: none;
}

.u-margin {
  margin-bottom: 1em;
}
.u-margin-small {
  margin-bottom: 0.5em;
}
.u-margin-none {
  margin: 0 !important;
}
.u-margin-top {
  margin-top: 1em !important;
}
.u-margin-bottom {
  margin-bottom: 1em !important;
}
.u-margin-left {
  margin-left: 1em !important;
}
.u-margin-right {
  margin-right: 1em !important;
}

.u-ratio--1-1 {
  aspect-ratio: 1/1;
}
.u-ratio--1-1 img,
.u-ratio--1-1 video {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}

.s-active-menu {
  overflow: hidden;
}
.s-active-menu .c-header--invert,
.s-active-menu .c-header--invert-logo > a {
  filter: none;
}

.s-scrolling:not(.home):not(.s-active-menu) .c-header {
  padding-top: 1em;
  padding-bottom: 1em;
  background: #fff;
  transition: padding 300ms linear, background 400ms 250ms ease-out;
}
.s-scrolling:not(.home):not(.s-active-menu) .c-header img {
  height: 1.5em;
}
.s-scrolling:not(.home):not(.s-active-menu) .c-header.c-header--invert,
.s-scrolling:not(.home):not(.s-active-menu) .c-header.c-header--invert-logo > a {
  filter: invert(0);
}