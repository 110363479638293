body {
  .cc-revoke,
  .cc-window {
    font-family: Inter, sans-serif !important;
  }

  .cc-link {
    text-decoration: none !important;
    padding: 0 !important;
  }

  .cc-btn {
    font-weight: normal !important;
  }
}
