/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  display: flex;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  bottom: 0;
  right: 0;
  font-size: 2em;
  font-weight: bold;
  z-index: -1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.3s ease-out;
}

.c-main-nav--active {
  opacity: 1;
  transform: translateY(0);
}

.c-main-nav__item {
  list-style: none;
  margin: 0 0 0.5em;
  padding: 0;
  width: calc(100vw - 3em);

  @include bp(medium) {
    width: 40vw;
  }

  &:last-of-type {
    margin: 0;
  }
}

.c-main-nav__link {
  display: block;
  border-bottom: none;
  transition: $global-transition;

  @include bp(medium) {
    display: flex;
    align-items: center;
  }
}

.current-menu-item .c-main-nav__link {
  border-bottom: 0.05em solid $color-border;
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;

  .c-main-nav__item:hover & {
    display: flex;
  }
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  background: $color-white;
  border-bottom: 1px solid $color-border;
  display: block;
  padding: 0.5em;
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: $color-black;
}

.c-main-nav-trigger {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;

  &::after {
    display: block;
    content: url('../assets/images/menu.svg');
  }
}

.c-main-nav-trigger--active {
  &::after {
    display: block;
    content: url('../assets/images/close.svg');
  }
}

// Modifiers

.c-main-nav--white {
  .c-main-nav__link {
    color: $color-bg;
  }
}
