.c-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  animation-name: wrapper-in;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  &__video {
    opacity: 0;
    max-width: 1280px;
    max-height: 70vh;
    margin: 0 auto;
    animation-name: video-in;
    animation-duration: 600ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    animation-delay: 600ms;
    width: calc(100vw - 6em);
    aspect-ratio: 16/9;
  }

  &__close {
    position: absolute;
    top: 3em;
    right: 3em;
    color: white !important;
    cursor: pointer;
  }
}

@keyframes wrapper-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes video-in {
  from {
    opacity: 0;
    transform: translateY(2em);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
