.s-scrolling:not(.home):not(.s-active-menu) {
  .c-header {
    padding-top: 1em;
    padding-bottom: 1em;
    background: $color-white;
    transition: padding 300ms linear, background 400ms 250ms ease-out;
  }

  .c-header img {
    height: 1.5em;
  }

  .c-header.c-header--invert,
  .c-header.c-header--invert-logo > a {
    filter: invert(0);
  }
}
