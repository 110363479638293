.c-staggered-scroll {
  margin-top: 20vh;
  margin-bottom: 10vh;

  @include bp(medium) {
    &__row {
      display: flex;
      justify-content: space-between;

      &:nth-child(even) {
        flex-direction: row-reverse;
      }
    }

    &__big {
      position: sticky;
      overflow: hidden;
      height: calc(100vh - 3.5em);
      width: 66%;
      top: 3.5em;

      img {
        height: 82vh;
        width: 100%;
        object-fit: cover;
      }
    }

    &__small {
      width: 32%;
    }
  }
}
